body {
  background: linear-gradient(0deg, #121212 0%, rgba(12, 12, 12, 0.8) 50%, rgba(12, 12, 12, 0.8) 100%), url('./assets/background.png');
  background-color: #121212;
  color: #ffffff;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App {
  min-height:  100vh;
  backdrop-filter: blur(20px);
}

.App-header {
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.55);
  min-height: 60px;
  padding-left: 20px;

  justify-content: center;
  display: flex;
  flex-direction: column;
}

.App-content {
  display: flex;
  flex-direction: column;
  /* align-items: left;
  justify-content: center; */
  font-size: calc(10px + 1vmin);
  padding-top: 3vh;
  padding-left: 10vw;
  padding-right: 10vw;
}

.App-content h2 {
  font-size: calc(10px + 2vmin);
  font-weight: normal;
}

.App-content p {
  opacity: 0.5;
}

.App-content span.title {
  font-size: calc(10px + 1vmin);
  font-weight: normal;
  padding-bottom: 1vh;
  display: inline-block;
  opacity: 0.7;
  padding-right: 1vw;
  padding-bottom: 1vh;
}

.App-content small {
  font-size: calc(5px + 1vmin);
  font-weight: normal;
  padding-bottom: 1vh;
  display: inline-block;
  opacity: 0.3;
}

.App-content .btn {
  color: #ffffff;
  background: #000000;
  display: inline-block;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 1vh;
}

.App-content img.lang-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
}

.App-content p.nopadding {
  padding-top: 0px;
  margin-top: 0px;
}

/* .App-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 100px;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
